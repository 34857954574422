import React from 'react'
// import { Login, Layout } from 'starterComponents'

const PdfPage = () => {
  // const iframeRef = React.useRef(null)
  const iFrameLoaded = (e) => {}

  return (
    // <Layout>
    <iframe
      title="pdf-page"
      // ref={iframeRef}
      onLoad={iFrameLoaded}
      onError={(error) => console.log('error: ', error)}
      src="https://firebasestorage.googleapis.com/v0/b/fir-101-d06e1.appspot.com/o/pdfs%2Ftech-library%2F95-43.pdf?alt=media&token=123"
      style={{
        position: 'fixed',
        // top: "92px",
        top: '0px',
        bottom: '0px',
        right: '0px',
        width: '100%',
        border: 'none',
        margin: '0',
        padding: '0',
        overflow: 'hidden',
        zIndex: '999999',
        height: '100%',
      }}
    />
    // </Layout>
  )
}

export default PdfPage
